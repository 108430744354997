import React, { useEffect } from "react"
import { Flex, Box } from "rebass/styled-components"
import { Heading, Text } from "rebass/styled-components"
import BackgroundImage from "gatsby-background-image"

const MenuList = ({ menu }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => (document.body.style.overflow = "unset")
  }, [])

  return (
    <Flex height="100%">
      <Box width={[0, 1 / 2, 1 / 2]}>
        <BackgroundImage
          style={{
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: `center ${menu.alignImage}`,
            backgroundRepeat: "no-repeat",
          }}
          fluid={menu.image.childImageSharp.fluid}
        />
      </Box>
      <Box
        width={[1, 1, 1 / 2]}
        py={3}
        px={4}
        height="100%"
        sx={{ overflowY: "scroll" }}
      >
        <Flex justifyContent="space-between">
          <Heading fontSize={7} color="accent" mb={2}>
            {menu.name}
          </Heading>
        </Flex>
        {menu.menu.map(m => (
          <Box key={m.section}>
            <Heading
              fontFamily="body"
              fontSize={4}
              color="text"
              mb={2}
              sx={{ textTransform: "uppercase" }}
            >
              {m.section}
            </Heading>
            <Box mb={3}>
              {m.items.map(i => (
                <Box key={i.name} mb={3}>
                  <Text
                    fontFamily="body"
                    fontWeight="bold"
                    fontSize={2}
                    color="text"
                    mb={1}
                    dangerouslySetInnerHTML={{ __html: i.name }}
                  />

                  {i.notes?.map(({ note }) => (
                    <Text
                      fontFamily="body"
                      fontWeight="bold"
                      fontSize={2}
                      color="accent"
                    >
                      {note}
                    </Text>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Flex>
  )
}

export default MenuList
