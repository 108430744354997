import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Flex, Box } from "rebass/styled-components"
import { useLocation } from "@reach/router"
import Heading from "../../components/shared/heading"
import MenuList from "./menuList"
import Modal from "react-modal"
import BackgroundImage from "gatsby-background-image"

Modal.setAppElement("#___gatsby")

const Menu = ({ menus }) => {
  const { hash } = useLocation()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalMenu, setModalMenu] = useState(menus[0].node)

  useEffect(() => {
    if (hash) {
      const modalMenu = menus.find(({ node: menu }) => `#${menu.hash}` === hash)
      if (modalMenu) {
        setModalMenu(modalMenu.node)
        setModalIsOpen(true)
      }
    } else {
      setModalMenu(menus[0].node) // probably a bit redundant
      setModalIsOpen(false)
    }
  }, [hash, menus])

  return (
    <>
      <Flex width="100%" flexWrap="wrap">
        {menus
          .sort((a, b) => a.node.order - b.node.order)
          .map(({ node: menu }) => (
            <Box
              key={menu.id}
              width={[1, 1 / 2, 1 / 3]}
              height="50vh"
              onClick={() => navigate(`/menu#${menu.hash}`)}
              sx={{ cursor: "pointer" }}
            >
              <BackgroundImage
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: `center ${menu.alignImage}`,
                  backgroundRepeat: "no-repeat",
                }}
                fluid={menu.image.childImageSharp.fluid}
              >
                <Flex
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ background: "rgba(0, 0, 0, .5)" }}
                >
                  <Heading style={{ color: "white", fontSize: "50px" }}>
                    {menu.name}
                  </Heading>
                </Flex>
              </BackgroundImage>
            </Box>
          ))}
      </Flex>

      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => navigate(`/menu`)}
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, .5)", zIndex: 9000 },
          content: {
            height: "70vh",
            padding: 0,
            top: "50%",
            left: "10%",
            right: "10%",
            transform: "translateY(-50%)",
            border: "none",
          },
        }}
      >
        <MenuList menu={modalMenu} />
      </Modal>
    </>
  )
}

export default Menu
