import styled from "styled-components"

const Heading = styled.h1`
  margin: ${({ theme: { space } }) => `0, ${space[2]}px`};
  color: ${({ theme: { colors } }) => colors.accent};
  font-family: ${({ theme: { fonts } }) => fonts.heading};
  font-size: ${({ theme: { fontSizes } }) => fontSizes[5]}px;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.heading};
  text-transform: uppercase;
`

export default Heading
