import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Menu from "../components/menu"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Menu" />
    <Menu menus={data.allMenuJson.edges} />
  </Layout>
)

export const query = graphql`
  query {
    allMenuJson {
      edges {
        node {
          id
          name
          order
          hash
          description
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alignImage
          menu {
            section
            items {
              name
              notes {
                note
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
